import { StyleSheet, Text } from "@react-pdf/renderer"

export interface PDFLigneCommentaireProps {
    text: string;
}

export const PDFLigneCommentaire = ({ text }: PDFLigneCommentaireProps) => {
    const styles = pdfLigneCommentaireStyles

    return (
        <Text style={styles.commentaire}>{text}</Text>
    )
}

export const pdfLigneCommentaireStyles = StyleSheet.create({
    commentaire: {
        paddingLeft: 12,
        width: '95%'
    }
})