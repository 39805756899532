import { Affaire } from "../class/affaire";


/*

export const affairesFormatter = (affaires: any): Affaire[] => {
    if (!Array.isArray(affaires)) {
        return []; 
    }
    return affaires.map((affaire: any): Affaire => affaireFormatter(affaire));
    //affaires.map((affaire: any):Affaire => affaireFormatter(affaire));
}
*/
export const affairesFormatter = (affaires: any): Affaire[] =>
    affaires.map((affaire: any): Affaire => affaireFormatter(affaire));

export function affaireFormatter(affaire: any): Affaire {
    return {
        id: parseInt(affaire.id_data_dossier),
        numero_dossier: affaire.numero_dossier,
        metier: affaire.metier,
        nom_charge_affaire: affaire.nom_charge_affaire || '',
        tel_charge_affaire: affaire.tel_charge_affaire || '',
        nom_client: affaire.nom,
        mail_client: affaire.mail_client,
        tel_client: affaire.tel_client,
        tel_sinistre: affaire.tel_sinistre,
        adresse_client: affaire.adresse_client,
        compl1_client: affaire.compl1_client,
        compl2_client: affaire.compl2_client,
        code_postal_client: affaire.code_postal_client,
        ville_client: affaire.ville_client,
        localisation_client: affaire.localisation_clientt,
        date_creation: new Date(affaire.date_creation),
        date_visite: new Date(affaire.date_visite),
        dossier: affaire.dossier,
        tiers: affaire.tiers,
        contact_expert: affaire.contact_expert,
        ref_expert_cie_assurance: affaire.ref_expert_cie_assurance,
        code_do: affaire.code_do,
        ref_plateforme: affaire.ref_plateforme,
        enseigne_do: affaire.enseigne_do,
        nom_expert: affaire.nom_expert,
        nom_plateforme: affaire.nom_plateforme,
        nom_autre: affaire.nom_autre,
        nom_do: affaire.nom_do,
        ref_sinistre: affaire.ref_sinistre,
        code_agence: affaire.code_agence,
        nom_agence: affaire.nom_agence,
        agence_adr_rue: affaire.agence_adr_rue,
        agence_adr_compl1: affaire.agence_adr_compl1,
        agence_adr_compl2: affaire.agence_adr_compl2,
        agence_adr_loc: affaire.agence_adr_loc,
        agence_adr_ville: affaire.agence_adr_ville,
        agence_adr_cp: affaire.agence_adr_cp,
        agence_email: affaire.agence_email,
        agence_tel: affaire.agence_tel,
        agence_rib: affaire.agence_rib,
        prenom_sinistre: affaire.prenom_sinistre,
        nom_sinistre: affaire.nom_sinistre,
        rue_sinistre: affaire.rue_sinistre,
        compl1_sinistre: affaire.compl1_sinistre,
        compl2_sinistre: affaire.compl2_sinistre,
        ville_sinistre: affaire.ville_sinistre,
        localisation_sinistre: affaire.loc_sinistre,
        cp_sinistre: affaire.cp_sinistre,
        mail_assu_do: affaire.mail_assu_do,
        mail_expert_do: affaire.mail_expert_do,
        mail_autre_do: affaire.mail_autre_do,
        mail_plateforme_do: affaire.mail_plateforme_do,
        display_addr_assure: affaire.display_addr_assure,
        type_sinistre: affaire.type_sinistre,
        chat: affaire.chat
    };
};

