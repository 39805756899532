import Header from "../components/header/header";

const Qualite = () => {
    return (
        <div className="content-wrapper w-100">
            <Header titre={'Qualité'} icone={'comments fa-regular'} infos={false} recherche={false} />
            <main className="container-fluid scrollbar listedevis bg-bleu-clair2 pos-r height-6">
            </main>
        </div>
    );
};
export default Qualite;