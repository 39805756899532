// FullscreenButton.tsx
import React, { useState, useEffect } from 'react';
import BtnTooltips from './btn-tooltips';

interface FullscreenButtonProps {
    isFullscreen: boolean;
    toggleFullscreen: () => void;
}

const FullscreenButton = ({ isFullscreen, toggleFullscreen }: FullscreenButtonProps) => {
    useEffect(() => {
        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.code === 'Escape') {
                event.preventDefault();
                if (document.fullscreenElement) {
                    toggleFullscreen();
                }
            }
        };

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.code === 'Escape' || event.code === 'F11') {
                event.preventDefault();
                if (document.fullscreenElement) {
                    toggleFullscreen();
                }
            }
        };

        document.addEventListener('keyup', handleKeyUp);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keyup', handleKeyUp);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [toggleFullscreen]);

    return (
        <span className="btn btn-primary m-2 end-0 position-absolute" onClick={toggleFullscreen}>
            <BtnTooltips
                tooltiplabel={isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
                buttonstyle={`nobt d-flex align-items-center fa-solid fa-${isFullscreen ? 'compress' : 'expand'} blanc`}
            />
        </span>
    );
};

export default FullscreenButton;
