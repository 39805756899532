
import { StyleSheet, Text, View } from "@react-pdf/renderer"

export const PDFFooter = () => {
    const styles = getPdfFooterStyleLocal();

    return (
        <View style={styles.footer} fixed>
            <View style={styles.pageNumber}>
                <Text render={({ pageNumber, totalPages }) => `Page ${pageNumber}/${totalPages}`} />
            </View>
            <View>
                <Text>Site internet : https://resilians.fr/ - E-mail : contact@resilians.fr</Text>
                {/* <Text>SA au capital de 444 000 € - SIRET B 394 620 298 000 31 - NAF 3250B - TVA FR 59 394 620 298</Text> */}
                {/* <Text>B.N.P CHATEAU-THIERRY 30004 00136 00020771913 22</Text> */}
            </View>
        </View>
    )
}

export const getPdfFooterStyleLocal = () => {
    return StyleSheet.create({
        footer: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            bottom: 10,
            left: 0,
            right: 0,
            fontSize: 8,
            textAlign: 'center',
            lineHeight: 1.5,
            fontFamily: 'Open Sans', /// FONT FAMILY A CHANGER
        },

        pageNumber: {
            fontSize: 10,
            paddingRight: 15,
            textAlign: 'right',
            color: 'grey',
            fontWeight: 300
        },

    })
}
