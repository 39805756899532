import { useState } from 'react';
import axios from 'axios';
import { API } from '../../../Constantes';
import { Loadering } from '../../tools/loadering';


export const TestMailFunction = async (email: string, templateID: number, setLoading: (loading: boolean) => void) => {
    setLoading(true);

    const response = await axios
        .post(API + "mailtest", {
            email: email,
            templateID: templateID,
        })
        .then(function (response: any) {
            //console.log(response.message);              
            return response.data;
        })
        .catch(function (error: any) {
            //console.log(error.message);              
            return { error: error.message };
        })
        .finally(() => {
            setLoading(false);
        });

    /* , {
         headers: authHeader()
     });*/
    //console.log(response);
    return response;
}
interface MailtesterProps {
    templateID?: any;
}

const MailTester = ({ templateID }: MailtesterProps) => {

    const [inputValueEmail, setInputValueEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailResponse, setEmailResponse] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const validateEmail = (email: string) => {
        // Regular expression for a valid email format
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        console.log(emailRegex.test(email));
        return emailRegex.test(email);
    };

    const handleInputEmailChange = (e: { target: { value: any; }; }) => {
        const email = e.target.value;
        setInputValueEmail(email);
        setIsValidEmail(validateEmail(email));
    };

    const testMail = async () => {

        if (!inputValueEmail) {
            setInputError(true);
            setEmailResponse(null);
        } else {
            setLoading(true);
            setInputError(false);
            setEmailResponse(null);
            const response = await TestMailFunction(inputValueEmail, templateID, (isLoading) => {
                setLoading(isLoading); // Pass a callback to update loading state
            });
            setEmailResponse(response);
            setEmailSent(true);
        }
    };

    return (
        <div className="my-4 text-center pt-2">
            <div className="text-start">
                {inputError ? <span className="rouge d-block">Veuillez renseigner un email valide pour tester l'envoi </span> : ''}
                <div className={` ${window.innerWidth > 768 ? 'input-group' : ''}  mb-3 has-validation`}>
                    <input
                        type="hidden"
                        name="templateID"
                        id="templateID"
                        value={templateID}
                    />
                    <input
                        type="email"
                        id="emailDest"
                        name="emailDest"
                        className={`form-control ${inputError ? 'is-invalid' : ''}  ${isValidEmail ? 'is-valid' : ''}  my-1`}
                        placeholder="email de destination"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                        value={inputValueEmail}
                        onChange={(e) => {
                            handleInputEmailChange(e);
                            setInputValueEmail(e.target.value);
                            setInputError(false);

                        }}
                    />
                    <button className="btn btn-primary nowrap my-1" type="button" id="mailTestBtn" onClick={testMail}>
                        Envoyer un email de test
                    </button>
                </div>
                {loading && <Loadering color={'bleu-actif'} formodal={true} />}
                {emailSent && emailResponse && !loading && (
                    <div className={`alert ${emailResponse.error ? 'alert-danger' : 'alert-success'}`}>
                        {emailResponse.error ? emailResponse.error : <span dangerouslySetInnerHTML={{ __html: emailResponse.message }} />}
                    </div>
                )}

            </div>
        </div>
    );
};


export default MailTester;