import formatMontant, { formatPourcent } from "../../../functions/formatMontant";


interface DevisBodyTotalPrpos {
    titre?: string;
    editable: boolean;
    isFinal?: boolean;
    montant?:number;
    tva?:number;
    montantTva?:number;
}

export const DevisBodyTotal = ({ titre, editable, isFinal, montant, tva, montantTva }: DevisBodyTotalPrpos) => {


    const bold = isFinal? ' font-bold' : '';

    return (
        <li className={`ligne-article ${isFinal? ' border-0 border-top border-black bg-bleu-clair8' : ''}`}>
            <div className={"colonne-11 justify-content-end"+bold}><span>{titre}</span></div>
            <div className={`lh-1 colonne-5 justify-content-end`+bold}><span>{formatMontant(montant, true)}</span></div>
            <div className="lh-1 colonne-4 justify-content-end"><span>{!isFinal && (formatPourcent(tva||0)+' %')}</span></div>
            <div className={`lh-1 colonne-5 justify-content-end`+bold}><span>{formatMontant(montantTva, true)}</span></div>
            {editable && <div className="colonne-4 border-0 d-none d-md-block"> </div>}
        </li>
    )
}