import { roundAtDec } from "./maths";

function formatMontant(montant: number | undefined, showZero?: boolean) {
  if (!montant && !(montant === 0 && showZero)) return '';

  const formattedNumber = new Intl.NumberFormat('fr-CA', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(montant);

  return formattedNumber;
};

export default formatMontant;

export const addSpaceToMillers = (numberString: string | number): string =>
  (numberString + '').replace(' ', '').replace('.', ',').replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');

export const formatPourcent = (number: number, dec=2): string => addSpaceToMillers(roundAtDec(number, dec))
export const formatPrice = formatPourcent;

export const formatEur = (numberString: string | number): string =>
  addSpaceToMillers(numberString).replace(/,(\d)$/, ',$10')




// Supprime les chiffres après X chiffres après la virgule
export const delAfterVirgule = (numberStr: string | number, position: number): string => {
  const stringifiedNumber = String(numberStr);
  const parts = stringifiedNumber.split(',');
  if (parts.length > 1 && position < parts[1].length) {
    parts[1] = parts[1].substring(0, position);
  }
  return parts.join(',');
};


export const formatPriceToNumber = (nb: number | string) => {
  const nbVal = parseFloat((nb + '').replaceAll(' ', '').replaceAll(',', '.'))
  return isNaN(nbVal) ? '' : nbVal;
}
